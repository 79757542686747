import { FelaCSS } from '../../../../components/fela/flowtypes';
import { colors } from '../../../../themes/variables';
import { SharedTypes } from './circle';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = ({
  size,
  color = 'white',
  borderWidth,
  borderColor = 'black',
}: SharedTypes): IStyles => {
  const diameter = size ? `${size}px` : '20px';
  const bgColor = colors[color];
  const boardColor = colors[borderColor];

  const borderStyle: FelaCSS = borderWidth
    ? {
        borderWidth: `${borderWidth}px`,
        borderColor: boardColor,
        borderStyle: 'solid',
      }
    : {};

  return {
    wrapper: {
      ...borderStyle,
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      width: diameter,
      height: diameter,
      borderRadius: '100%',
      backgroundColor: bgColor,
      boxSizing: 'border-box',
    },
  };
};

export default styles;
