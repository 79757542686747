import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';
import { SharedTypes } from './tooltip';

interface StyleTypes {
  wrapper: FelaCSS;
  content: FelaCSS;
  circleWrap: FelaCSS;
  questionMark: FelaCSS;
  tipWrapper: FelaCSS;
  arrow: FelaCSS;
}

const styles = ({
  orientation,
  multiline,
  offsetX,
  noQuestionMark,
  noPadding,
  maxWidth = 286,
  questionMarkAlignedWithBaseLine,
}: SharedTypes): StyleTypes => {
  const isTop: boolean = Boolean(orientation && orientation.includes('top-'));
  const isRight: boolean = Boolean(orientation && orientation.includes('-right'));
  const isMiddle: boolean = Boolean(orientation && orientation.includes('middle'));

  const triangle = (color: string): FelaCSS => ({
    width: '0',
    height: '0',
    borderWidth: isTop ? '0px 13px 14px 13px' : '14px 13px 0 13px',
    borderColor: isTop
      ? `transparent transparent ${color} transparent`
      : `${color} transparent transparent transparent`,
    borderStyle: 'solid',
  });

  const offsetXSet: FelaCSS = offsetX
    ? {
        marginLeft: offsetX && !isRight ? `${offsetX}px` : undefined,
        marginRight: isRight ? `${offsetX * -1}px` : undefined,
      }
    : {};

  const bottomLeft: FelaCSS = {
    left: '0',
    bottom: '100%',
  };

  const bottomRight: FelaCSS = {
    right: '0',
    bottom: '100%',
  };

  const bottomMiddle: FelaCSS = {
    left: '50%',
    bottom: '100%',
    transform: 'translateX(-50%)',
  };

  const topLeft: FelaCSS = {
    left: '0',
    top: '100%',
  };

  const topRight: FelaCSS = {
    right: '0',
    top: '100%',
  };

  const topMiddle: FelaCSS = {
    left: '50%',
    top: '100%',
    transform: 'translateX(-50%)',
  };

  const rightBottom: FelaCSS = {
    left: '100%',
    bottom: 0,
    transform: 'translateY(20px) translateX(20px)',
  };

  const tipOrientation = (): FelaCSS => {
    switch (orientation) {
      case 'bottom-left':
        return bottomLeft;
      case 'bottom-right':
        return bottomRight;
      case 'bottom-middle':
        return bottomMiddle;
      case 'top-left':
        return topLeft;
      case 'top-right':
        return topRight;
      case 'top-middle':
        return topMiddle;
      case 'right-bottom':
        return rightBottom;
      default:
        return bottomLeft;
    }
  };

  const arrowBottomLeftPosition: FelaCSS = {
    left: '20px',
    top: '100%',
  };

  const arrowBottomRightPosition: FelaCSS = {
    right: '20px',
    top: '100%',
  };

  const arrowBottomMiddlePosition: FelaCSS = {
    left: '50%',
    top: '100%',
    transform: 'translateX(-50%)',
  };

  const arrowTopLeftPosition: FelaCSS = {
    left: '20px',
    bottom: '100%',
  };

  const arrowTopRightPosition: FelaCSS = {
    right: '20px',
    bottom: '100%',
  };

  const arrowTopMiddlePosition: FelaCSS = {
    left: '50%',
    bottom: '100%',
    transform: 'translateX(-50%)',
  };

  const arrowRightBottomPosition: FelaCSS = {
    left: '-20px',
    bottom: '20px',
    transform: 'rotate(90deg)',
  };

  const arrowPositionSwitch = (): FelaCSS => {
    switch (orientation) {
      case 'bottom-left':
        return arrowBottomLeftPosition;
      case 'bottom-right':
        return arrowBottomRightPosition;
      case 'bottom-middle':
        return arrowBottomMiddlePosition;
      case 'top-left':
        return arrowTopLeftPosition;
      case 'top-right':
        return arrowTopRightPosition;
      case 'top-middle':
        return arrowTopMiddlePosition;
      case 'right-bottom':
        return arrowRightBottomPosition;
      default:
        return arrowBottomLeftPosition;
    }
  };

  return {
    wrapper: {
      display: 'inline-flex',
      position: 'relative',
      cursor: 'default',
    },

    content: {
      paddingRight: noQuestionMark ? undefined : '17px',
    },

    circleWrap: {
      justifyContent: 'center',
      position: 'absolute',
      right: '-1px',
      top: questionMarkAlignedWithBaseLine ? '2px' : '-5px',
    },

    questionMark: {
      fontDefaultSemiBold: 11,
      lineHeight: '14px',
      color: colors.indigoCrush,
    },

    tipWrapper: {
      ...tipOrientation(),
      ...offsetXSet,
      position: 'absolute',
      minWidth: '58px',
      maxWidth: `${maxWidth}px`,
      width: multiline ? `${maxWidth}px` : 'auto',
      fontDefault: 12,
      lineHeight: '1.33em',
      color: colors.space60,
      textAlign: isMiddle ? 'center' : undefined,
      borderWidth: '1px',
      borderColor: colors.space15,
      borderStyle: 'solid',
      borderRadius: '4px',
      boxShadow: `0 5px 10px 0 rgba(0, 0, 0, 0.12)`,
      boxSizing: 'border-box',
      whiteSpace: multiline ? undefined : 'nowrap',
      background: colors.white,
      zIndex: 1000,
      marginTop: isTop ? '16px' : undefined,
      marginBottom: isTop ? undefined : '16px',
      ...(!noPadding && {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        paddingBottom: '16px',
      }),
    },

    arrow: {
      ...arrowPositionSwitch(),
      position: 'absolute',
      width: '26px',
      height: '14px',

      ':before': {
        ...triangle(colors.space15),
        content: '""',
        position: 'absolute',
        left: '0',
        top: isTop ? '-1px' : '1px',
      },

      ':after': {
        ...triangle(colors.white),
        content: '""',
        position: 'absolute',
        left: '0',
        top: '0',
      },
    },
  };
};

export default styles;
