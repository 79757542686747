import React, { PropsWithChildren } from 'react';
import Box from '../../../../components/fela/Box';
import { IStylingProps } from '../../../../themes/types';
import { TColor } from '../../../../types';
import componentStyles from './circle.style';

export interface SharedTypes {
  size?: number;
  color?: TColor;
  borderWidth?: number;
  borderColor?: TColor;
}

interface Props extends SharedTypes, IStylingProps, PropsWithChildren {}

const Circle = ({ size, color, borderWidth, borderColor, children, ...restProps }: Props) => {
  const styles = componentStyles({ size, color, borderWidth, borderColor });

  return (
    <Box style={styles.wrapper} {...restProps} data-name="circle">
      {children}
    </Box>
  );
};

export default Circle;
