import { mergeDeepRight } from 'ramda';
import React, { ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import Box from '../../../components/fela/Box';
import { FelaCSS } from '../../../components/fela/flowtypes';
import { IStylingProps } from '../../../themes/types';
import { Tooltip, TooltipOrientation } from '../tooltip/tooltip';
import componentStyles from './tooltip-list.style';

export type TooltipListItem = {
  key: string;
  icon?: ReactNode;
  content: string | ReactNode;
  isSelected?: boolean;
  onClick?: (e: SyntheticEvent<any>) => void;
};

interface IProps extends IStylingProps {
  items: TooltipListItem[];
  render: (isOpened: boolean) => ReactNode;
  orientation?: TooltipOrientation;
  width?: number;
  style?: FelaCSS;
  offsetX?: number;
  title?: string;
}

const TooltipList = ({
  items = [],
  style = {},
  orientation = 'top-right',
  width,
  render,
  offsetX = 0,
  title,
  ...restProps
}: IProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const styles = componentStyles({ width });

  const tooltipContent = useMemo(
    () => (
      <Box style={mergeDeepRight(styles.wrapper, style)}>
        {title && <Box style={styles.title}>{title}</Box>}
        <Box>
          {items.map(({ key, isSelected, icon, content, onClick }) => (
            <Box
              style={styles.itemWrap(isSelected)}
              key={key}
              onClick={onClick}
              data-name={`owner-tooltip-${key}`}>
              {icon && <Box mr={2}>{icon}</Box>}
              <Box style={styles.content}>{content}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    ),
    [items, style, styles, title],
  );

  return (
    <Tooltip
      showOnClick
      noQuestionMark
      onToggle={(val) => setIsOpened(val)}
      tip={tooltipContent}
      offsetX={offsetX}
      noPadding
      orientation={orientation}
      maxWidth={width}
      {...restProps}>
      {render(isOpened)}
    </Tooltip>
  );
};

export default TooltipList;
