import React, {
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, FelaCSS, IStylingProps } from '@bridebook/ui';
import { usePrevious } from '@bridebook/ui/src/utils/hooks';
import Circle from '../../../components/bbcommon/molecules/circle/circle';
import componentStyles from './tooltip.style';

export type TooltipOrientation =
  | 'bottom-middle'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-middle'
  | 'top-left'
  | 'top-right'
  | 'right-bottom';

export interface SharedTypes {
  // Sets position relative to content
  orientation?: TooltipOrientation;
  // True value turns on text wrapping and fixes overflowing text
  multiline?: boolean;
  // Set to true to make tooltip appear/disappear on click
  showOnClick?: boolean;
  // Set to true to open tooltip from outside event
  show?: boolean;
  // Changes x-axis offset
  offsetX?: number;
  // Hides tooltip question mark above content
  noQuestionMark?: boolean;
  // Removes inner padding
  noPadding?: boolean;
  // Set max width of a tooltip
  maxWidth?: number;
  questionMarkAlignedWithBaseLine?: boolean;
}

interface IProps extends SharedTypes, IStylingProps, PropsWithChildren {
  // Pass custom styles to content wrap, icon and content
  wrapperStyle?: FelaCSS;
  contentStyle?: FelaCSS;
  iconStyle?: FelaCSS;
  // Pass markup or text to display inside a tooltip
  tip: string | ReactNode;
  // Called on tooltip show/hide event, receives false/true argument
  onToggle?: (visible: boolean) => void;
}

export const Tooltip = ({
  children,
  tip,
  wrapperStyle,
  orientation,
  multiline,
  offsetX,
  noQuestionMark,
  noPadding,
  maxWidth,
  show,
  onToggle,
  showOnClick,
  questionMarkAlignedWithBaseLine,
  iconStyle,
  contentStyle,
  ...restProps
}: IProps) => {
  const [isHovered, setIsHovered] = useState(show || false);
  const tooltipRef = useRef<HTMLDivElement>();
  const prevShow = usePrevious(show);

  const _onToggle = useCallback(
    (toggle: boolean) => {
      if (onToggle) onToggle(toggle);
    },
    [onToggle],
  );

  useEffect(() => {
    if (show !== prevShow && typeof show === 'boolean') {
      _onToggle(show);
      setIsHovered(show);
    }
  }, [_onToggle, prevShow, show]);

  const _onActiveToggle = () => {
    if (!isHovered) {
      document.addEventListener('touchstart', _onClickOutside, { capture: true, once: true });
      document.addEventListener('click', _onClickOutside, { capture: true, once: true });
    }

    _onToggle(!isHovered);
    setIsHovered(!isHovered);
  };

  const _onClickOutside = (e: any) => {
    if (!tooltipRef.current?.contains(e.target)) {
      _onClose();
    }
  };

  const _onHover = () => {
    if (!showOnClick && !show) _onActiveToggle();
  };

  const _onClick = (e: SyntheticEvent<any>) => {
    if (showOnClick) {
      e.preventDefault();
      e.stopPropagation();
      _onActiveToggle();
    }
  };

  const onContentClick = (e: SyntheticEvent<any>) => {
    // Do not propagate click event to outside elements
    e.stopPropagation();
  };

  const _onClose = () => {
    const page = document.body;

    page.removeEventListener('touchstart', _onClickOutside);
    page.removeEventListener('click', _onClickOutside);
    _onToggle(false);
    setIsHovered(false);
  };

  const styles = componentStyles({
    orientation,
    multiline,
    offsetX,
    noQuestionMark,
    noPadding,
    maxWidth,
    questionMarkAlignedWithBaseLine,
  });

  return (
    <Box
      style={{ ...styles.wrapper, ...wrapperStyle }}
      onMouseEnter={_onHover}
      onMouseLeave={_onHover}
      {...restProps}>
      <div ref={tooltipRef}>
        <Box style={{ ...styles.content, ...contentStyle }} onClick={_onClick}>
          {children}
          {!noQuestionMark && (
            <Box style={{ ...styles.circleWrap, ...iconStyle }}>
              <Circle size={16} borderWidth={1} borderColor="space15">
                <Box style={styles.questionMark}>?</Box>
              </Circle>
            </Box>
          )}
        </Box>

        {isHovered && (
          <Box style={styles.tipWrapper} onClick={onContentClick}>
            {tip}
            <Box style={styles.arrow} />
          </Box>
        )}
      </div>
    </Box>
  );
};
