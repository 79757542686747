import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';

interface IStyles {
  wrapper: FelaCSS;
  itemWrap: (isSelected: boolean) => FelaCSS;
  content: FelaCSS;
  title: FelaCSS;
}

interface IStyleProps {
  width?: number;
}

const styles = ({ width }: IStyleProps): IStyles => ({
  wrapper: {
    minWidth: '250px',
    ...(width && { width }),
    maxHeight: '400px',
    overflowY: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    gap: 8,
  },

  itemWrap: (isSelected) => ({
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '2px',
    marginBottom: '2px',
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    minHeight: '32px',
    ...(isSelected
      ? {
          fontDefaultSemiBold: 'inherit',
          background: colors.space04,
          borderRadius: '8px',
        }
      : { fontDefault: 'inherit' }),

    ':hover': {
      background: colors.space04,
      borderRadius: '8px',
    },
  }),

  content: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontDefault: 14,
    lineHeight: '18px',
    color: colors.space,
  },

  title: {
    color: colors.space,
    fontDefaultSemiBold: 12,
  },
});

export default styles;
